@font-face {
  font-family: 'Dulcinea';
  src: url('assets/fonts/Dulcinea.eot');
  src: local('Dulcinea'),
    url('assets/fonts/Dulcinea.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Dulcinea.woff2') format('woff2'),
    url('assets/fonts/Dulcinea.woff') format('woff'),
    url('assets/fonts/Dulcinea.ttf') format('truetype'),
    url('assets/fonts/Dulcinea.svg#Dulcinea') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

html {
  background: rgb(224, 199, 201);
  background: -moz-linear-gradient(270deg, rgba(224, 199, 201, 0.6) 0%, rgba(244, 212, 211, 0.6) 25%, rgba(245, 236, 201, 0.6) 50%, rgba(208, 240, 216, 0.6) 75%, rgba(210, 213, 250, 0.6) 100%);
  background: -webkit-linear-gradient(270deg, rgba(224, 199, 201, 0.6) 0%, rgba(244, 212, 211, 0.6) 25%, rgba(245, 236, 201, 0.6) 50%, rgba(208, 240, 216, 0.6) 75%, rgba(210, 213, 250, 0.6) 100%);
  background: linear-gradient(270deg, rgba(224, 199, 201, 0.6) 0%, rgba(244, 212, 211, 0.6) 25%, rgba(245, 236, 201, 0.6) 50%, rgba(208, 240, 216, 0.6) 75%, rgba(210, 213, 250, 0.6) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e0c7c9", endColorstr="#d2d5fa", GradientType=1);
  font-family: 'Dulcinea', sans-serif;
}

.bg-image {
  background: url('assets/bg-image.png') center center no-repeat;
  background-size: 450px;
}
